<template>
  <div class="flow-slot-app">
    <component
      :is="flowData.flowCompTag"
      :index="index"
      :flow-data="flowData"
    />
  </div>
</template>

<script>

import { defineComponent, defineAsyncComponent, provide } from 'vue'

import ListBanner from './list_banner/Container.vue' // ListBanner用defineAsyncComponent包裹水合时会报错

export default defineComponent({
  name: 'FlowSlotApp',
  components: {
    // 对应flowCompTag
    RANK_LIST_COMPONENT: defineAsyncComponent(() => import('./rank_list_component/Container.vue')),
    LIST_BANNER_COMP: ListBanner,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    flowData: {
      type: Object,
      required: true,
    },
    sheinClubInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props) {
    provide('FLOW_SLOT_TOKEN', {
      suggestedSaleType: props.flowData.suggestedSaleType,
      sheinClubInfo: props.sheinClubInfo,
    })
    return {}
  },
})
</script>

<style lang="less">
.flow-slot-app {
  --item-horizontal-gap: 4px;

  padding: 8px;

  // 从商卡样式抄过来的
  @media (max-width: 768px) {
    width: calc(50% - (1 * 0.5 * var(--item-horizontal-gap)));
  }
  @media (min-width: 768px) {
    width: calc(33.33% - (2 * 0.34 * var(--item-horizontal-gap)));
  }
  @media (min-width: 1200px) {
    width: calc(25% - (3 * 0.25 * var(--item-horizontal-gap)));
  }
  @media (min-width: 1800px) {
    width: calc(20% - (4 * 0.2 * var(--item-horizontal-gap)));
  }
  @media (min-width: 2300px) {
    width: calc(16.66% - (5 * 0.17 * var(--item-horizontal-gap)));
  }
}
</style>
