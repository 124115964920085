<template>
  <CccxComp 
    v-if="flowData.version === 'v2'"
    :cccx-context="flowData.cccxContext"
    :content="flowData.content"
    class="not-fsp-element"
  />
  <!-- 老ccc -->
  <div
    v-else
    class="not-fsp-element"
  >
    <FlowAd
      :context="flowData.context"
      :prop-data="flowData.propData"
      :ccc-cate-info="flowData.cccCateInfo"
      :ada-level="0"
    />
  </div>
</template>

<script>
/**
 * @file 广告流banner
 */

import { defineComponent } from 'vue'

import FlowAd from '../../FlowAd.vue'
import CccxComp from '../../CccxComp.vue'

export default defineComponent({
  name: 'ListBanner',
  components: {
    FlowAd,
    CccxComp,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    flowData: {
      type: Object,
      default: () => ({}),
    },
  },
})
</script>
